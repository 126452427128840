import React from "react"
import Layout from "../../components/layout"
import CommentsFB from "../../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Poemas = () => {
  return (
    <Layout>
      <GatsbySeo
        title="Cafayate canto - Poemas"
        titleTemplate="%s | Eduardo Ceballos"
        description="Cafayate canto"
        canonical="https://eduardoceballos.com/poemas/cafayate-canto"
        openGraph={{
          url: "https://eduardoceballos.com/poemas/cafayate-canto",
          title: "Cafayate canto - Poemas",
          description: "Cafayate canto",
          images: [
            {
              url: "https://eduardoceballos.com/seo/poemas.jpg",
              alt: "Poemas",
            },
          ],
          site_name: "Eduardo Ceballos",
        }}
        twitter={{
          handle: "@PoetadeSalta",
          site: "@PoetadeSalta",
          cardType: "summary_large_image",
        }}
      />
      <h1>Cafayate canto</h1>
      <center>
        <p>
          Abandono el trabajo ciudadano
          <br />y empiezo a recorrer los antiguos caminos del Valle de Lerma,
          <br />
          para buscar el oxígeno, el sol,
          <br />
          el paraíso donde vive la arena,
          <br />
          el canto y el vino, sabedor de abuelos y de coplas.
          <br />
          Dejo las pequeñas frustradas lágrimas ciudadanas
          <br />
          en una casa cualquiera de la ciudad de Salta,
          <br />y emprendo como un pájaro alucinado un vuelo
          <br />
          hacia el corazón sonoro de esta América que canta.
          <br />
          Surco pueblo, ríos, acequias, árboles
          <br />
          que me saludan con sus verdes y frescos brazos,
          <br />
          siento que una música de ancestros le crece desde su tallo,
          <br />
          El viento me lleva y voy envuelto en la emoción de siempre.
          <br />
          Surco pueblos y soy testigo de este abrazo
          <br />
          de valles y de ríos que bajan con sus aguas,
          <br />y la tonada exacta del coplerío,
          <br />
          los colores y las formas se parecen a la música.
          <br />
          Veo las casas de los loros que ponen verde el horizonte,
          <br />
          un obelisco salteño, vallisto,
          <br />
          un sapo mitológico sabedor de la vida,
          <br />
          Castillos donde viven la arena y los vientos,
          <br />
          Ventanas por donde mira el emocionado hombre,
          <br />
          el paisaje donde ruge un bravío calchaquí,
          <br />
          con melena de oro, que pasa alegre y orgulloso,
          <br />
          alimentando la algarroba y el maíz.
          <br />
          Su tesoro una chicha maíz,
          <br />
          aloja fermentada de la fiesta
          <br />
          cruzan los valles de la gente.
          <br />
          Que retumbe en los valles
          <br />
          El eco de los tiempos.
        </p>
      </center>
      <div class="autores">
        <div class="autor">
          <h6>Eduardo Ceballos.</h6>
          <img
            src="https://res.cloudinary.com/lagexpress/image/upload/f_auto,q_auto,r_max,q_100,w_100,h_100//strapi/Foto_a321272643"
            alt=""
          />
        </div>
      </div>
      <CommentsFB />
    </Layout>
  )
}

export default Poemas
